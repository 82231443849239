
import './App.css';
import Landing from './components/landing';
import Example from "./components/nav";
import Intro from './components/intro';

function App() {
  return (
    <div className="App">

         
          <Example />
           <Intro/>
            <Landing />
        
          
    </div>
  );
}

export default App;
